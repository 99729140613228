process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
/*
export const _GLOBAL = {
  // URLBACKEND: 'http://192.168.3.127:11340',
  URLBACKEND: 'http://localhost:11340',
  URLBACKEND_ABSENSI_WIFI_LOCAL: 'http://apieofficelocal.bpdp.or.id'
}
*/

export const _GLOBAL = {
  URLBACKEND: 'https://apieoffice.bpdp.or.id',
  // URLBACKEND_ABSENSI_WIFI_LOCAL: 'https://apieofficelocal.bpdp.or.id'
  URLBACKEND_ABSENSI_WIFI_LOCAL: 'https://apieoffice.bpdp.or.id'
}
