import axios from 'axios'
import { _GLOBAL } from '@/api/server'

// import { Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: _GLOBAL.URLBACKEND, // api 的 base_url
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    if(localStorage.getItem('jwt') != null){
      let token = localStorage.getItem('jwt')
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  error => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => response,
  error => {
    // console.log(error.message) // for debug
    var err = error.message
    // var log = err.includes("404")
    console.log(err) // for debug
    if(err.includes("401")){ //Authentication failed then logout
      this.$store.state.user = null
      localStorage.removeItem('user')
      localStorage.removeItem('jwt')

      window.location = '/'
    }else if(!err.includes("404") && !err.includes("500")){
      /*Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })*/
    }
    return Promise.reject(error)
  }
)

export default service
